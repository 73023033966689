@import url('https://fonts.googleapis.com/css2?family=Neucha&display=swap');

:root{
    --DOL-background: 	hsl(28, 59%, 88%);
    --DOL-shadow: hsl(35, 69%, 67%);
    --DOL-Text:	hsl(184, 32%, 40%);
    --DOL-Info:	hsl(28, 75%, 63%);


    --LOD-background: 	hsl(184, 22%, 70%);
    --LOD-Text: #FFF;

    --Canvas-background: hsl(184, 9%, 90%);

    --ButtonHue:184;
    --ButtonSaturation:55%;
    --SaveButtonHue:118;
    --ButtonLightness:37%;
    --ButtonHoverLightness:45%;
    --ButtonClickeLightness:28%;

    --MED-background:#e89c5b;

    --Highlight:#FEDD01;
}

body {
    margin: 0;
    background-image: linear-gradient(to bottom right, var(--DOL-background), var(--DOL-shadow));
    background-attachment: fixed;
    font-size: 40px;
    height: 100%;
  }
  
.App
{
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: "Neucha", cursive;
    color:var(--LOD-Text);
}
.OuterContainer
{
    background-color:  var(--LOD-background);
    padding: 50px;
    width: 90%;
    height: auto;
    display: flex;
    min-height: 600px;
    border-radius: 10px;
}
.TopBar
{
    background-color: var(--MED-background);
    width: 100%;
}

.PrimaryCanvas
{
    background-color: var(--Canvas-background);
    margin: 0px;
    display: block;
}
.CanvasHolder
{

    background-color: var(--Canvas-background);
    width: 100%;
    max-height: 80vh;
    overflow: scroll;
    padding: 10px;
}
.AppContainer
{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}


.stack {
    display: grid;
    position: relative;    
}
.stack > * 
{
    grid-area: 1/ 1 / 2 / 2;
}

.sticky
{
    top:0px;
    position: fixed;
}

.TargetLine
{
    position: relative;
    background-color: #963F3B;
    pointer-events: none;
    box-shadow: 0 0 10px #FFF;
    border: 1px solid #ffffff85;
}

.StackPanel
{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap:10px;
}
.StackPanel > * {
    /* This rule ensures that no specific item affects the general alignment */
    margin: 0;                       /* Removes margin that might affect alignment */
    align-self: flex-start;              /* Forces each child to center align individually */
  }

.CenterStackItem
{
    align-self: center;  
}

.CenterStackPanel
{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap:10px;
}
.CenterStackPanel > * {
    /* This rule ensures that no specific item affects the general alignment */
    margin: 0;                       /* Removes margin that might affect alignment */
    align-self: center;              /* Forces each child to center align individually */
  }



.CheckBox
{
    width: 20px;
    height: 20px;
    margin: 10px;
}

.ImageCover
{
    width: 100%;
    height: 100%;
    background-color: #0005;
}

.VerticalSlider
{
    
    writing-mode: vertical-lr;
    direction: rtl;
    margin-left: 20px;
    margin-right: 20px;
}
.FlexContainer
{
    display: flex;
    width: 100%;
    align-items: center;
}
.LastLeftItem
{
    margin-right: auto;
}
.FillFlex
{
    flex:1
}

.ArrowImage
{
    max-width: 100%;   /* Maximum width is 100% of the container */
    max-height: 100%;  /* Maximum height is 100% of the container */
    width: auto;       /* Width will be the natural size or less */
    height: auto;      /* Height will be the natural size or less */
    object-fit: contain;
    display: inline-block;
}
.HorizontalStackPanel
{
    flex: 0 0 auto;  /* Don't grow, don't shrink, auto-basis */
    display: flex;          /* Enables Flexbox */
    flex-direction: row;    /* Children laid out in a row (left to right) */
    align-items: center;    /* Aligns children vertically in the center */
    justify-content: flex-start; /* Aligns children to the start of the flex container */
    gap: 10px;
}
.HorizontalStackPanel > * {
    /* This rule ensures that no specific item affects the general alignment */
    margin: 0;                       /* Removes margin that might affect alignment */
    align-self: center;              /* Forces each child to center align individually */
  }

  .AlignSelfCenter
  {
    align-self: center;
  }

.FillHorizontal
{
    width: 100%;
}
.PercentBox
{
    min-width: 60px;
    max-width: 180px;
    height: 40px;
    font-size: 20px;
    margin: 0 20px;
    text-align: center;
}
.XYTitle
{
    margin: 0 20px
}
.Footer
{
    margin: 20px;
    font-size: 20px;
}

.HomebrewButton
{
    background-color: hsl(var(--ButtonHue), var(--ButtonSaturation), var(--ButtonLightness));
    border-radius: 20px;
    cursor: pointer;
    padding: 20px;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.HomebrewButton:hover
{
    background-color: hsl(var(--ButtonHue), var(--ButtonSaturation), var(--ButtonHoverLightness));
}
.HomebrewButton:active
{
    background-color: hsl(var(--ButtonHue), var(--ButtonSaturation), var(--ButtonClickeLightness));
}

.SaveButton
{
    background-color: hsl(var(--SaveButtonHue), var(--ButtonSaturation), var(--ButtonLightness));
}
.SaveButton:hover
{
    background-color: hsl(var(--SaveButtonHue), var(--ButtonSaturation), var(--ButtonHoverLightness));
}
.SaveButton:active
{
    background-color: hsl(var(--SaveButtonHue), var(--ButtonSaturation), var(--ButtonClickeLightness));
}

.TransparentButton
{
    background-color: hsl(0, 0%, 0%,0);
    cursor: pointer;
    padding: 6px;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.TransparentButton:hover
{
    background-color: hsl(0, 0%, 100%,.1);
}
.TransparentButton:active
{
    background-color: hsl(0, 0%, 0%,.1);
}
.TopbarText
{
    font-size: 30px;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: inherit;
    vertical-align: center;

}

.StepText
{
    display: inline;
    padding: 10px;
    align-self: center;
    color: var(--LOD-Text);
   text-shadow: -1px -1px 0 #000,
   1px -1px 0 #000,
   -1px 1px 0 #000,
   1px 1px 0 #000;  
}

.Icon
{
    height: 35px;
    object-fit: contain;
    margin:2px 5px;
}
.LargeIcon
{
    height: 55px;
    object-fit: contain;
    margin:10px 10px;
}

.GradientBase
{
    width: fit-content;
    background-image: radial-gradient(ellipse at center, #0007 0%,transparent 70%);
}

#ImageColumn
{
    flex-grow: 1;
}
.ToolColumn
{
    flex-grow: 0;
    align-self: right;
}
.Shadow 
{
    box-shadow: 0 0 10px #000;
    border: 1px solid #000a;
}
.Wrapper
{
    display: inline-block;
}

.ImageUploadButton
{
    cursor: pointer;
}
#uploadedImage
{
    max-width: 100%;
}

.PulseGradient 
{
    width: fit-content;
    background-image: radial-gradient(ellipse at center, #000c 0%,transparent 70%);
    animation: gradientPulse 1s ease-in-out infinite;
    padding: 20px;
    margin: -20px;
}

@keyframes gradientPulse 
{
    0% {
        opacity: 1;
    }
    50% {
       opacity: 0.8;
    }
    100% {
        opacity: 1;
    }
  }
.Heading
{
    font-size: 100px;
    padding: 30px 0;
    z-index: 1;
    text-align: center;
    color:var(--DOL-Text)
}
.SubHeading
{
    font-size: 50px;
    margin: 0 0px 30px 0;
    color:var(--DOL-Info)
}
.CoffeeButtonImage
{
    max-height: 60px;
    border-radius: 10px;
}

.CoffeeButtonContainer
{
    width: fit-content;
    height: fit-content;
    padding: 0px;
    margin: 10px;
    right: 0;
    bottom:0;
    position: fixed;
    

}
.FloatRight
{
    float:right
}
.FillRightMargin
{
    margin-right: auto;
}

.container {
    display: flex;
    justify-content: space-between; /* Aligns items to the start, center, and end */
}

.left {
    text-align: left;
}

.center {
    text-align: center;
}

.right {
    text-align: right;
}

.Contact{
    color: var(--DOL-Text);
    margin: 100px;
    text-align: center;
}
.ContactEmail{
    margin: 100px 0px;
    text-decoration: none;
    color: inherit;
    font-size: 60px;
}
.TitleLogo
{
    height: 100px;
}

.AdContainer
{
    min-height: 200px;
    width: 100%;
}